<template>
  <v-container id="categorias_extras" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-baby-carriage"
      inline
      :title="$t('extras_category')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nueva Categoría de Extras</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        :width="420"
        v-model="isSure"
        @click="cancel"
      />

      <!-- Dialogo Agregar/Editar Categoría Extras -->
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Categoria -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Categoría*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.categoria.$model"
                    :error-messages="categoriaErrors"
                    @blur="$v.editedItem.categoria.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.categoria.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Descripcion -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Descripción*"
                    maxlength="50"
                    required
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    @blur="$v.editedItem.descripcion.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.descripcion.$reset()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Data Table de Categorías de Extras -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="categorias"
        :loading="isLoading"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>
        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!</template
        >
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="categorias" :dataFields="fields" fileName="CategoriasExtras" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },

  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    headers: [
      {
        text: 'Categoría de Servicios Adicionales',
        align: 'start',
        sortable: false,
        value: 'categoria',
      },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    categorias: [],
    value: [],
    defaultItem: {
      _id: '',
      categoria: '',
      descripcion: '',
      activo: true,
    },
    editedItem: {
      _id: '',
      categoria: '',
      descripcion: '',
      activo: true,
    },
    fields: {
      Categoría: 'categoria',
      Descripción: 'descripcion',
      Activo: 'activo',
    },
  }),

  mixins: [validationMixin],
  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      categoria: { required, minLength: minLength(3), maxLength: maxLength(20) },
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
    },
  },

  computed: {
    // Errores del campo Categoria en el formulario
    categoriaErrors() {
      const errors = []
      if (!this.$v.editedItem.categoria.$dirty) return errors
      !this.$v.editedItem.categoria.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.categoria.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.categoria.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    // Errores del campo Descripcion en el formulario
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva Categoría de Extras' : 'Editar Categoría de Extras'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerServicios()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('extraCategory/deleteExtraCategory', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de zonas
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * paises
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de zonas
     */
    close(checkbox) {
      this.changed = false
      this.isSure = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.categorias.indexOf(item)
      this.editedItem = Object.assign({}, item)
      //Confirma cerrar formulario
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.categorias.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar categorias
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.categorias.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todas las categorías de extras
     */
    async obtenerServicios() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('extraCategory/fetchExtraCategory', payload)
        .then((response) => {
          this.categorias = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para una categoria de extras nueva o
     * la edicion de una
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          categoria: this.editedItem.categoria,
          descripcion: this.editedItem.descripcion,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('extraCategory/editExtraCategory', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizó con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          Object.assign(this.categorias[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('extraCategory/addExtraCategory', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.categorias.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
